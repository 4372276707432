
	* {
		box-sizing: border-box;
		outline: 0;
		/* font-family: "Open Sans",sans-serif !important; */
		padding: 0px;
		margin: 0px;
	}

	body {
		position: relative;
	}

	body:not(.CodeMirror) {
		font-family:"Open Sans", sans-serif;
	}

	h1,
	h2,
	h3,
	h4 {
		margin:0;
	}

	img {
		max-width: 100%;
	}

	p {
		margin: 0;
		padding: 0;
	}

	a {}
	a:focus,
	a:after,
	a:before,
	a:visited,
	a:hover {
		text-decoration: none;
		cursor: pointer;
	}


	
