.starting_soon {
    color: #0083d3;
    margin-top: 30px;
    font-size: 3em;
    font-weight: bold;
}

.follow-us {
    color: #0083d3;
    font-size: 2em;
    font-weight: bold;
    margin-right: 15px;
}

.follow-us-div {
    margin-top: 20px;
    display: inline-flex;
}

.follow-us-logo {
    margin: 0px 15px;
    width: 40px;
    height: 40px;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block
}

body {
    line-height: 1.5
}

ol,
ul {
    list-style: none
}

blockquote,
q {
    quotes: none
}

blockquote:after,
blockquote:before,
q:after,
q:before {
    content: '';
    content: none
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

* {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0)
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: local("Open Sans Bold"), local("OpenSans-Bold"), url(https://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UN7rgOXOhpKKSTj5PW.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: local("Open Sans Bold"), local("OpenSans-Bold"), url(https://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UN7rgOUuhpKKSTjw.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"), url(https://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UNirkOXOhpKKSTj5PW.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"), url(https://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UNirkOUuhpKKSTjw.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: local("Open Sans Light"), local("OpenSans-Light"), url(https://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UN_r8OXOhpKKSTj5PW.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: local("Open Sans Light"), local("OpenSans-Light"), url(https://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UN_r8OUuhpKKSTjw.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}
html {
    font-size: 16px
}

html .womix__backgroundcliptext .womix__gradient {
    background: -webkit-gradient(linear, left top, left bottom, from(#0083d3), color-stop(51%, #0cbabe), to(#15e4ae));
    background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
    -webkit-background-clip: text;
    color: transparent
}

html.womix__no-backgroundcliptext .womix__gradient {
    background: 0 0;
    color: #0083d3
}

html.womix__no-backgroundcliptext body main>section.womix__horizontal-module-small .module-wrapper .womix__image-wrapper video {
    width: 100%
}

.womix__button {
    margin-top: 25px;
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 5px 35px;
    border: 2px solid #fff;
    border-radius: 20px;
    -webkit-transition: all .4s cubic-bezier(.445, .05, .55, .95);
    transition: all .4s cubic-bezier(.445, .05, .55, .95);
    cursor: pointer;
    height: 35px;
    min-width: 125px
}

.womix__button a,
.womix__button button,
.womix__button form,
.womix__button input,
.womix__button p,
.womix__button span {
    display: inline-block;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 1.2em;
    line-height: 1em;
    color: #fff;
    padding: 0;
    margin: 0 !important;
    min-width: 0;
    border: none;
    outline: 0;
    background: 0 0;
    background-color: transparent;
    vertical-align: text-top;
    -webkit-transition: color .4s cubic-bezier(.445, .05, .55, .95);
    transition: color .4s cubic-bezier(.445, .05, .55, .95);
    text-decoration: none
}

.womix__button * {
    line-height: 1em;
    height: 1em
}

.womix__button:after {
    content: '';
    position: absolute
}

@media screen and (min-width:813px) {
    .womix__button:hover {
        background: #fff
    }

    .womix__button:hover a,
    .womix__button:hover button,
    .womix__button:hover form,
    .womix__button:hover input,
    .womix__button:hover p,
    .womix__button:hover span {
        color: #0083d3
    }
}

::-webkit-media-controls-panel {
    display: none !important;
    -webkit-appearance: none
}

::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none
}

::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none
}

.slick-slider {
    position: relative;
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0
}

.slick-list:focus {
    outline: 0
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand
}

.slick-slider .slick-list,
.slick-slider .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto
}

.slick-track:after,
.slick-track:before {
    content: "";
    display: table
}

.slick-track:after {
    clear: both
}

.slick-loading .slick-track {
    visibility: hidden
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none
}

[dir=rtl] .slick-slide {
    float: right
}

.slick-slide img {
    display: block
}

.slick-slide.slick-loading img {
    display: none
}

.slick-slide.dragging img {
    pointer-events: none
}

.slick-initialized .slick-slide {
    display: block
}

.slick-loading .slick-slide {
    visibility: hidden
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent
}

.slick-arrow.slick-hidden {
    display: none
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
    padding: 15px 15px;
    display: inline-block;
    cursor: pointer;
    -webkit-transition-property: opacity, -webkit-filter;
    transition-property: opacity, -webkit-filter;
    transition-property: opacity, filter;
    transition-property: opacity, filter, -webkit-filter;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-transition-timing-function: linear;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible
}

.hamburger:hover {
    opacity: .7
}

.hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px
}

.hamburger-inner,
.hamburger-inner::after,
.hamburger-inner::before {
    width: 40px;
    height: 4px;
    background-color: #000;
    border-radius: 4px;
    position: absolute;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease
}

.hamburger-inner::after,
.hamburger-inner::before {
    content: "";
    display: block
}

.hamburger-inner::before {
    top: -10px
}

.hamburger-inner::after {
    bottom: -10px
}

.hamburger--elastic .hamburger-inner {
    top: 2px;
    -webkit-transition-duration: .55s;
    transition-duration: .55s;
    -webkit-transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55);
    transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55)
}

.hamburger--elastic .hamburger-inner::before {
    top: 10px;
    -webkit-transition: opacity .25s .55s ease;
    transition: opacity .25s .55s ease
}

.hamburger--elastic .hamburger-inner::after {
    top: 20px;
    -webkit-transition: -webkit-transform .55s cubic-bezier(.68, -.55, .265, 1.55);
    transition: -webkit-transform .55s cubic-bezier(.68, -.55, .265, 1.55);
    transition: transform .55s cubic-bezier(.68, -.55, .265, 1.55);
    transition: transform .55s cubic-bezier(.68, -.55, .265, 1.55), -webkit-transform .55s cubic-bezier(.68, -.55, .265, 1.55)
}

.hamburger--elastic.is-active .hamburger-inner {
    -webkit-transform: translate3d(0, 10px, 0) rotate(135deg);
    transform: translate3d(0, 10px, 0) rotate(135deg);
    -webkit-transition-delay: .15s;
    transition-delay: .15s
}

.hamburger--elastic.is-active .hamburger-inner::before {
    -webkit-transition-delay: 0s;
    transition-delay: 0s;
    opacity: 0
}

.hamburger--elastic.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -20px, 0) rotate(-270deg);
    transform: translate3d(0, -20px, 0) rotate(-270deg);
    -webkit-transition-delay: .15s;
    transition-delay: .15s
}

html {
    width: 100vw
}

html body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: inherit;
    min-height: inherit;
    overflow-x: hidden
}

html body.no-scroll {
    overflow: hidden
}

html body h1,
html body h2,
html body h3,
html body h4,
html body li,
html body p {
    max-width: 100%
}

html body .womix__members_overlay {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    background-color: transparent;
    z-index: 4;
    pointer-events: none;
    -webkit-transition: all .6s cubic-bezier(.175, .885, .32, 1.275);
    transition: all .6s cubic-bezier(.175, .885, .32, 1.275)
}

html body .womix__members_overlay .overlay_content {
    padding: 10px;
    border: 2px solid #fff;
    border-radius: 10px;
    -webkit-transition: all .6s cubic-bezier(.6, -.28, .735, .045);
    transition: all .6s cubic-bezier(.6, -.28, .735, .045);
    background-color: #0083d3;
    color: #fff
}

html body .womix__members_overlay .overlay_content.authentication>ul {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

html body .womix__members_overlay .overlay_content.authentication>ul li {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 48%;
    height: 50px;
    cursor: pointer;
    border: 2px solid #0083d3;
    -webkit-transition: all .4s cubic-bezier(.445, .05, .55, .95);
    transition: all .4s cubic-bezier(.445, .05, .55, .95);
    border-radius: 5px
}

html body .womix__members_overlay .overlay_content.authentication>ul li p {
    position: relative
}

html body .womix__members_overlay .overlay_content.authentication>ul li p:after {
    position: absolute;
    content: '';
    width: 6px;
    height: 6px;
    border: 2px solid #fff;
    border-left: none;
    border-bottom: none;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    right: -12px;
    top: calc(50% - 3px);
    opacity: 0;
    -webkit-transition: all .4s cubic-bezier(.445, .05, .55, .95);
    transition: all .4s cubic-bezier(.445, .05, .55, .95)
}

html body .womix__members_overlay .overlay_content.authentication>ul li:hover:not(.active) p:after {
    opacity: 1
}

html body .womix__members_overlay .overlay_content.authentication>ul li.active {
    border-color: #fff
}

html body .womix__members_overlay .overlay_content.authentication .womix__members_forms {
    position: relative;
    padding: 20px
}

html body .womix__members_overlay .overlay_content.authentication .womix__members_forms>div {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none
}

html body .womix__members_overlay .overlay_content.authentication .womix__members_forms>div.active {
    opacity: 1;
    position: relative;
    pointer-events: all;
    -webkit-transition: opacity .4s cubic-bezier(.445, .05, .55, .95);
    transition: opacity .4s cubic-bezier(.445, .05, .55, .95)
}

html body .womix__members_overlay .overlay_content.authentication .womix__members_forms>div form .success-response {
    display: none;
    position: relative;
    background-color: transparent;
    opacity: 1;
    top: auto;
    left: auto;
    height: auto
}

html body .womix__members_overlay .overlay_content.authentication .womix__members_forms>div form .womix__members_reset {
    cursor: pointer
}

html body .womix__members_overlay .overlay_content.authentication .womix__members_forms>div .reset-password-form {
    display: none;
    margin-top: 20px
}

html body .womix__members_overlay * {
    pointer-events: none
}

html body .womix__members_overlay.active {
    opacity: 1;
    pointer-events: all;
    background-color: #0083d3
}

html body .womix__members_overlay.active.transparent {
    background-color: rgba(255, 255, 255, .8)
}

html body .womix__members_overlay.active * {
    pointer-events: all
}

html body .info-layer {
    background: #fff;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 70px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: .3s cubic-bezier(.445, .05, .55, .95);
    transition: .3s cubic-bezier(.445, .05, .55, .95);
    z-index: 11;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

html body .info-layer .bwki-logo {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px 0
}

@media screen and (max-width:812px) {
    html body .info-layer .bwki-logo {
        display: none
    }
}

html body .info-layer .bwki-logo h1 {
    padding-left: 4px;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.5em;
    font-size: 1em
}

html body .info-layer .bwki-logo h1 span {
    font-weight: 600
}

html body .info-layer .bwki-logo .womix__image-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%
}

html body .info-layer .bwki-logo .womix__image-wrapper div {
    height: 100%;
    margin-right: 25px
}

html body .info-layer .bwki-logo .womix__image-wrapper div img {
    display: block;
    max-height: 50px
}

html body .info-layer .bwki-logo .womix__image-wrapper div:first-of-type {
    max-height: 40px
}

html body .info-layer .bwki-logo .womix__image-wrapper div:first-of-type img {
    max-height: 40px
}

html body .info-layer .womix__members {
    height: 100%;
    display: none;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 0 10px 0 auto
}

html body .info-layer .womix__members .womix__members-content {
    height: 50px
}

html body .info-layer .womix__members .womix__members-content .womix__members_icon {
    height: 100%;
    cursor: pointer
}

html body .info-layer .womix__members .womix__members-content .womix__members_icon img {
    height: 100%;
    width: auto
}

html body .info-layer .additional-icons {
    height: 100%;
    position: absolute;
    right: 10px;
    top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

html body .info-layer .additional-icons .womix__image-wrapper {
    height: 100%
}

html body .info-layer .additional-icons .womix__image-wrapper a {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-decoration: none
}

html body .info-layer .additional-icons .womix__image-wrapper a img {
    height: 50%;
    width: auto
}

html body .info-layer .additional-icons .womix__image-wrapper a span {
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.5em;
    color: #0083d3;
    font-size: 1em
}

html body .info-layer .additional-icons .womix__countdown {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 24px;
    font-size: 2.5vw;
    line-height: 1.5em;
    font-size: 2em
}

@media (max-width:52em) {
    html body .info-layer .additional-icons .womix__countdown {
        font-size: 1.3em
    }
}

@media (min-width:120em) {
    html body .info-layer .additional-icons .womix__countdown {
        font-size: 3em
    }
}

html body .info-layer.scrolling {
    -webkit-box-shadow: 1px 10px 48px 1px rgba(0, 0, 0, .5);
    box-shadow: 1px 10px 48px 1px rgba(0, 0, 0, .5);
    -webkit-transition: .3s cubic-bezier(.445, .05, .55, .95);
    transition: .3s cubic-bezier(.445, .05, .55, .95);
    background: #fff;
    height: 70px
}

html body nav {
    position: fixed;
    width: 30%;
    height: 100vh;
    min-width: 550px;
    z-index: 9999;
    pointer-events: none
}

html body nav .hamburger {
    z-index: 14;
    position: absolute;
    cursor: pointer;
    left: 0;
    top: 0;
    height: 70px;
    pointer-events: visible
}

html body nav .hamburger>span>span {
    background-color: #0083d3
}

html body nav .hamburger>span>span:after,
html body nav .hamburger>span>span:before {
    background-color: #0083d3
}

html body nav canvas {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0
}

html body nav ul {
    position: absolute;
    z-index: 12;
    height: inherit;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: calc(100% - 150px);
    padding-top: 10vh
}

@media screen and (max-width:812px) {
    html body nav ul {
        padding-top: 15vh
    }
}

html body nav ul li {
    cursor: pointer;
    padding: 10px;
    opacity: 0;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

html body nav.is-active ul li {
    opacity: 0;
    transform: matrix(1, 0, 0, 1, 20, 0);
}

html body nav ul li:first-of-type {
    margin-bottom: 8vh
}

@media screen and (max-width:320px) {
    html body nav ul li:first-of-type {
        padding-left: 20px;
        margin-bottom: 5vh
    }
}

html body nav ul li a,
html body nav ul li p {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 24px;
    font-size: 3vw;
    line-height: 1.2;
    text-transform: uppercase;
    text-decoration: none
}

@media (max-width:50em) {

    html body nav ul li a,
    html body nav ul li p {
        font-size: 1.5em
    }
}

@media (min-width:83.33333em) {

    html body nav ul li a,
    html body nav ul li p {
        font-size: 2.5em
    }
}

html body nav ul li .bwki-logo {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 5px
}

html body nav ul li .bwki-logo p {
    padding-left: 4px;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.5em;
    font-size: 1em;
    margin-bottom: 10px
}

html body nav ul li .bwki-logo p span {
    font-weight: 600
}

html body nav ul li .bwki-logo .womix__image-wrapper:first-of-type {
    width: 135px;
    margin-bottom: 10px
}

html body nav ul li .bwki-logo .womix__image-wrapper:last-of-type {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end
}

html body nav ul li .bwki-logo .womix__image-wrapper:last-of-type img {
    width: 30px
}

html body nav ul li .bwki-logo .womix__image-wrapper:last-of-type p {
    margin: 0 0 0 10px;
    font-size: .8em
}

@media screen and (max-width:320px) {
    html body nav ul li .bwki-logo .womix__image-wrapper:last-of-type p {
        font-size: .8em
    }
}

html body nav ul li .bwki-logo .womix__image-wrapper img {
    display: block;
    width: 100%
}

@media screen and (max-width:600px) {
    html body nav {
        min-width: 100vw
    }
}

html body nav.is-active {
    pointer-events: visible
}

html body nav.scrolling #hamburger {
    -webkit-transition: top .3s cubic-bezier(.445, .05, .55, .95);
    transition: top .3s cubic-bezier(.445, .05, .55, .95)
}

html body .womix__scroll-to-top {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #fff;
    position: fixed;
    right: 10px;
    bottom: 10px;
    z-index: 18;
    -webkit-box-shadow: 1px 10px 48px 1px rgba(0, 0, 0, .5);
    box-shadow: 1px 10px 48px 1px rgba(0, 0, 0, .5);
    opacity: 0;
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
    cursor: pointer
}

html body .womix__scroll-to-top:before {
    content: '';
    position: absolute;
    width: 25%;
    height: 25%;
    border: 2px solid #0083d3;
    border-left: none;
    border-bottom: none;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: 21px;
    left: 17px
}

html body .womix__scroll-to-top.active {
    opacity: 1;
    -webkit-transition: opacity .3s;
    transition: opacity .3s
}

html body header {
    padding-top: 100px;
    min-height: 100vh;
    background: #fff;
    position: relative
}

html body header .womix__header-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end
}

@media screen and (max-width:812px) {
    html body header .womix__header-content {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center
    }
}

html body header .womix__header-content .headline-cta-wraper {
    margin-left: 30px;
    padding: 10px
}

@media screen and (max-width:812px) {
    html body header .womix__header-content .headline-cta-wraper {
        text-align: center;
        margin-left: 0
    }
}

html body header .womix__header-content .headline-cta-wraper h2 {
    z-index: 1;
    text-transform: uppercase;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 45px;
    font-size: 5vw;
    line-height: 1.2
}

@media (max-width:30em) {
    html body header .womix__header-content .headline-cta-wraper h2 {
        font-size: 1.5em
    }
}

@media (min-width:90em) {
    html body header .womix__header-content .headline-cta-wraper h2 {
        font-size: 4.5em
    }
}



html body header .womix__header-content .headline-cta-wraper .womix__button {
    color: #fff;
    background: #0083d3;
    padding: 5px;
    padding: 22px 27px;
    border-radius: 30px;
    border: 3px solid #0083d3
}

html body header .womix__header-content .headline-cta-wraper .womix__button:hover {
    background: #fff;
    color: #0083d3
}

html body header .womix__header-content .womix__image-wrapper {
    max-height: 50vh;
    width: auto;
    max-width: 600px
}

@media screen and (max-width:1280px) {
    html body header .womix__header-content .womix__image-wrapper {
        max-width: 350px;
        max-height: 40vh
    }
}

@media screen and (max-width:812px) {
    html body header .womix__header-content .womix__image-wrapper {
        max-height: 30vh
    }
}

@media screen and (max-width:414px) {
    html body header .womix__header-content .womix__image-wrapper {
        max-height: 30vh
    }
}

html body header .womix__header-content .womix__image-wrapper img {
    max-height: inherit;
    max-width: 100%;
    display: block;
    margin-left: auto
}

@media screen and (max-width:414px) {
    html body header .womix__header-content .womix__image-wrapper img {
        margin: 0 auto
    }
}

html body header .womix__header-graphic {
    position: absolute;
    bottom: 0;
    overflow-x: hidden;
    width: 100%;
    height: 30%
}

html body header .womix__header-graphic svg {
    -webkit-filter: drop-shadow(rgba(0, 0, 0, .2) 0 0 8px);
    filter: drop-shadow(rgba(0, 0, 0, .2) 0 0 8px);
    position: absolute;
    left: 0;
    bottom: 0;
    width: 110%
}

html body header .womix__header-graphic svg.header_graphic-layer-1 {
    left: -15%;
}

html body header .womix__header-graphic svg.header_graphic-layer-1 polygon {
    fill: #15e4ae
}

html body header .womix__header-graphic svg.header_graphic-layer-2 {
    left: 10%;
}

html body header .womix__header-graphic svg.header_graphic-layer-2 polygon {
    fill: #0cbabe
}

html body header .womix__header-graphic svg.header_graphic-layer-3 {
    left: -5%;
}

html body header .womix__header-graphic svg.header_graphic-layer-3 polygon {
    fill: #0083d3
}

@media screen and (max-width:790px) {
    html body header {
        padding-top: 90px;
        max-height: 100vh;
        min-height: 80vh
    }
}

html body main {
    padding-top: 0;
    margin-top: -1px;
    z-index: 2;
    background: -webkit-gradient(linear, left top, left bottom, from(#0083d3), color-stop(51%, #0cbabe), to(#15e4ae));
    background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
    -ms-flex-negative: 0;
    flex-shrink: 0;
    width: 100%;
    float: left;
}

html body main>section {
    max-width: 1280px;
    margin: 150px auto 0 auto
}

html body main>section.womix__intro-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 15px;
    max-width: unset
}

html body main>section.womix__intro-text .inner-wrap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    width: 100%;
    max-width: 1280px;
    margin: auto;
    z-index: 99;
}

@media screen and (max-width:812px) {
    html body main>section.womix__intro-text .inner-wrap {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

html body main>section.womix__intro-text .inner-wrap .womix__video-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    max-height: 40vh;
    max-width: 100%;
    overflow: hidden;
    width: 50%
}

@media screen and (max-width:414px) {
    html body main>section.womix__intro-text .inner-wrap .womix__video-wrapper {
        max-height: 45vh
    }
}

@media screen and (max-width:812px) {
    html body main>section.womix__intro-text .inner-wrap .womix__video-wrapper {
        width: 100%
    }
}

html body main>section.womix__intro-text .inner-wrap .womix__video-wrapper>div {
    position: relative;
    padding-bottom: 56.25%;
    width: 100%
}

html body main>section.womix__intro-text .inner-wrap .womix__video-wrapper>div iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.womix__video-wrapper>div video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

html body main>section.womix__intro-text .inner-wrap .text-wrapper {
    width: 55%;
    padding-left: 15px
}

html body main>section.womix__intro-text .inner-wrap .text-wrapper h2 {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 36px;
    font-size: 5vw;
    line-height: 1.2;
    text-transform: uppercase;
    color: #fff
}

@media (max-width:48em) {
    html body main>section.womix__intro-text .inner-wrap .text-wrapper h2 {
        font-size: 2.4em
    }
}

@media (min-width:90em) {
    html body main>section.womix__intro-text .inner-wrap .text-wrapper h2 {
        font-size: 4.5em
    }
}

html body main>section.womix__intro-text .inner-wrap .text-wrapper>p {
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.5em;
    color: #fff
}

@media screen and (max-width:812px) {
    html body main>section.womix__intro-text .inner-wrap .text-wrapper>p {
        margin-top: 25px
    }
}

@media screen and (max-width:1280px) {
    html body main>section.womix__intro-text .inner-wrap .text-wrapper {
        width: 60%
    }
}

@media screen and (max-width:1024px) {
    html body main>section.womix__intro-text .inner-wrap .text-wrapper {
        width: 75%
    }
}

@media screen and (max-width:812px) {
    html body main>section.womix__intro-text .inner-wrap .text-wrapper {
        width: 100%;
        padding-left: 0
    }
}

html body main>section.womix__intro-text .womix__image-wrapper {
    overflow-x: hidden;
    overflow-y: hidden;
    opacity: .1;
    height: 75vh;
    right: 0;
    position: absolute;
    bottom: -30%;
    width: 100%;
    text-align: right;
    z-index: 9
}

html body main>section.womix__intro-text .womix__image-wrapper img {
    height: 100%
}

html body main>section.womix__horizontal-module {
    padding: 90px 0 50px;
    background: #fff;
    width: 100%;
    max-width: none;
    position: relative;
    -webkit-box-shadow: 0 3px 95px -11px rgba(0, 0, 0, .75);
    box-shadow: 0 3px 95px -11px rgba(0, 0, 0, .75)
}

html body main>section.womix__horizontal-module:before {
    content: '';
    display: block;
    position: absolute;
    top: -99px;
    width: 0;
    height: 0;
    border-bottom: 100px solid #fff;
    border-left: 100vw solid transparent
}

html body main>section.womix__horizontal-module:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -99px;
    width: 0;
    height: 0;
    border-top: 101px solid #fff;
    border-left: 100vw solid transparent
}

html body main>section.womix__horizontal-module .womix__text-module {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    max-width: 1280px;
    margin: auto;
    padding: 0 20px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.womix__text-module .info_main{
    min-height: 80rem;
}



.info_main .info-details{
    display: grid;
    grid-template-columns: 2fr 4fr;
    column-gap: 3rem;
    -webkit-column-gap: 10%;
    row-gap: 4rem;
    margin:2rem 0 2rem 0;

}

.info_main .info-details img{
    /* margin: 1rem; */
    display: flex;
    align-self: center;
}

.info_main .info-details p{
    margin: 0!important;
    display: flex;
    align-self: center;
}

html body main>section.womix__horizontal-module .womix__text-module>div {
    width: 100%;
    padding: 20px
}

@media screen and (max-width:812px) {
    html body main>section.womix__horizontal-module .womix__text-module>div {
        width: 100%
    }
}

html body main>section.womix__horizontal-module .womix__text-module>div h2 {
    text-transform: uppercase;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 36px;
    font-size: 5vw;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
}

@media (max-width:48em) {
    html body main>section.womix__horizontal-module .womix__text-module>div h2 {
        font-size: 2.4em
    }
}

@media (min-width:90em) {
    html body main>section.womix__horizontal-module .womix__text-module>div h2 {
        font-size: 4.5em
    }
}

html body main>section.womix__horizontal-module .womix__text-module>div p {
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.5em;
    margin-top: 25px;
    color: #000;
    text-align: justify
}

html body main>section.womix__quote-module {
    padding: 50px 0 50px;
    background: #fff;
    width: 100%;
    max-width: none;
    position: relative;
    -webkit-box-shadow: 0 3px 95px -11px rgba(0, 0, 0, .75);
    box-shadow: 0 3px 95px -11px rgba(0, 0, 0, .75)
}

html body main>section.womix__quote-module .content-wrapper {
    padding: 0 15px;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 1280px
}

html body main>section.womix__quote-module .content-wrapper .text-wrapper {
    width: 80%
}

html body main>section.womix__quote-module .content-wrapper .text-wrapper h2 {
    text-transform: uppercase;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 36px;
    font-size: 5vw;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center
}

@media (max-width:48em) {
    html body main>section.womix__quote-module .content-wrapper .text-wrapper h2 {
        font-size: 2.4em
    }
}

@media (min-width:90em) {
    html body main>section.womix__quote-module .content-wrapper .text-wrapper h2 {
        font-size: 4.5em
    }
}

html body main>section.womix__quote-module .content-wrapper .text-wrapper p {
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.5em;
    margin-top: 25px;
    color: rgba(0, 0, 0, .5);
    text-align: center
}

@media screen and (max-width:750px) {
    html body main>section.womix__quote-module .content-wrapper .text-wrapper {
        width: 100%
    }
}

html body main>section.womix__quote-module .content-wrapper .womix__image-wrapper {
    width: 30%
}

html body main>section.womix__quote-module .content-wrapper .womix__image-wrapper img {
    width: 100%
}

@media screen and (max-width:750px) {
    html body main>section.womix__quote-module .content-wrapper .womix__image-wrapper {
        display: none
    }
}

html body main>section.womix__quote-module:before {
    content: '';
    display: block;
    position: absolute;
    top: -99px;
    width: 0;
    height: 0;
    border-bottom: 100px solid #fff;
    border-left: 100vw solid transparent
}

html body main>section.womix__quote-module:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -99px;
    width: 0;
    height: 0;
    border-top: 100px solid #fff;
    border-left: 100vw solid transparent
}

html body main>section.womix__horizontal-module-small {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 1600px
}

.divRow > h2  .App-header > h2{
    text-transform: uppercase;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 30px;
    line-height: 1.2;
    text-transform: uppercase;
    color: #fff;
}


@media screen and (max-width:812px) {
    html body main>section.womix__horizontal-module-small {
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start
    }
}

html body main>section.womix__horizontal-module-small>h2 {
    text-transform: uppercase;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 36px;
    font-size: 5vw;
    line-height: 1.2;
    text-transform: uppercase;
    color: #fff;
    margin: 100px auto 5vw auto
}

@media (max-width:48em) {
    html body main>section.womix__horizontal-module-small>h2 {
        font-size: 2.4em
    }
}

@media (min-width:90em) {
    html body main>section.womix__horizontal-module-small>h2 {
        font-size: 4.5em
    }
}

@media screen and (max-width:812px) {
    html body main>section.womix__horizontal-module-small>h2 {
        margin-bottom: 60px
    }
}

html body main>section.womix__horizontal-module-small .womix__video-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    width: 50%
}

@media screen and (max-width:414px) {
    html body main>section.womix__horizontal-module-small .womix__video-wrapper {
        max-height: 45vh
    }
}

@media screen and (max-width:812px) {
    html body main>section.womix__horizontal-module-small .womix__video-wrapper {
        width: 100%
    }
}

html body main>section.womix__horizontal-module-small .womix__video-wrapper>div {
    position: relative;
    padding-bottom: 56.25%;
    width: 100%
}

html body main>section.womix__horizontal-module-small .womix__video-wrapper>div iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

html body main>section.womix__horizontal-module-small .module-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between
}

html body main>section.womix__horizontal-module-small .module-wrapper:not(:nth-child(2)) {
    margin-top: 250px
}

html body main>section.womix__horizontal-module-small .module-wrapper:nth-child(2) {
    margin-top: 100px
}

html body main>section.womix__horizontal-module-small .module-wrapper .tile {
    padding: 50px 0;
    background: #fff;
    width: 61vw;
    position: relative;
    -webkit-box-shadow: 0 3px 95px -11px rgba(0, 0, 0, .75);
    box-shadow: 0 3px 95px -11px rgba(0, 0, 0, .75)
}

@media screen and (min-width:1280px) {
    html body main>section.womix__horizontal-module-small .module-wrapper .tile {
        width: 60vw;
        max-width: 960px
    }
}

@media screen and (max-width:812px) {
    html body main>section.womix__horizontal-module-small .module-wrapper .tile {
        width: 90vw
    }
}

@media screen and (max-width:414px) {
    html body main>section.womix__horizontal-module-small .module-wrapper .tile {
        width: 100vw
    }
}

html body main>section.womix__horizontal-module-small .module-wrapper .tile .content-wrapper {
    padding: 0 70px;
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 900px;
    margin: auto
}

html body main>section.womix__horizontal-module-small .module-wrapper .tile .content-wrapper .text-wrapper {
    width: 100%
}

html body main>section.womix__horizontal-module-small .module-wrapper .tile .content-wrapper .text-wrapper h3 {
    text-transform: uppercase;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 36px;
    font-size: 5vw;
    line-height: 1.2;
    text-transform: uppercase
}

@media (max-width:48em) {
    html body main>section.womix__horizontal-module-small .module-wrapper .tile .content-wrapper .text-wrapper h3 {
        font-size: 2.4em
    }
}

@media (min-width:90em) {
    html body main>section.womix__horizontal-module-small .module-wrapper .tile .content-wrapper .text-wrapper h3 {
        font-size: 4.5em
    }
}

html body main>section.womix__horizontal-module-small .module-wrapper .tile .content-wrapper .text-wrapper p {
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.5em;
    margin-top: 25px;
    color: #000
}

@media screen and (max-width:812px) {
    html body main>section.womix__horizontal-module-small .module-wrapper .tile .content-wrapper .text-wrapper {
        width: 100%
    }
}

html body main>section.womix__horizontal-module-small .module-wrapper .womix__image-wrapper {
    width: 40%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

html body main>section.womix__horizontal-module-small .module-wrapper .womix__image-wrapper svg {
    width: 70%;
    opacity: .1
}

@media screen and (max-width:812px) {
    html body main>section.womix__horizontal-module-small .module-wrapper .womix__image-wrapper {
        display: none
    }
}

html body main>section.womix__horizontal-module-small .module-wrapper:nth-child(even) {
    -ms-flex-item-align: end;
    align-self: flex-end;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse
}

html body main>section.womix__horizontal-module-small .module-wrapper:nth-child(even) .tile:before {
    content: '';
    display: block;
    position: absolute;
    top: -99px;
    width: 0;
    height: 0;
    border-bottom: 100px solid #fff;
    border-left: 60vw solid transparent
}

@media screen and (min-width:1280px) {
    html body main>section.womix__horizontal-module-small .module-wrapper:nth-child(even) .tile:before {
        display: none
    }
}

@media screen and (max-width:812px) {
    html body main>section.womix__horizontal-module-small .module-wrapper:nth-child(even) .tile:before {
        border-left: 90vw solid transparent
    }
}

@media screen and (max-width:414px) {
    html body main>section.womix__horizontal-module-small .module-wrapper:nth-child(even) .tile:before {
        border-left: 100vw solid transparent
    }
}

html body main>section.womix__horizontal-module-small .module-wrapper:nth-child(even) .tile:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -99px;
    width: 0;
    height: 0;
    border-top: 100px solid #fff;
    border-left: 60vw solid transparent
}

@media screen and (min-width:1280px) {
    html body main>section.womix__horizontal-module-small .module-wrapper:nth-child(even) .tile:after {
        display: none
    }
}

@media screen and (max-width:812px) {
    html body main>section.womix__horizontal-module-small .module-wrapper:nth-child(even) .tile:after {
        border-left: 90vw solid transparent;
        bottom: -99px
    }
}

@media screen and (max-width:414px) {
    html body main>section.womix__horizontal-module-small .module-wrapper:nth-child(even) .tile:after {
        border-left: 100vw solid transparent
    }
}

html body main>section.womix__horizontal-module-small .module-wrapper:nth-child(odd) .tile:before {
    content: '';
    display: block;
    position: absolute;
    top: -99px;
    width: 0;
    height: 0;
    border-bottom: 100px solid #fff;
    border-right: 60vw solid transparent
}

@media screen and (min-width:1280px) {
    html body main>section.womix__horizontal-module-small .module-wrapper:nth-child(odd) .tile:before {
        display: none
    }
}

@media screen and (max-width:812px) {
    html body main>section.womix__horizontal-module-small .module-wrapper:nth-child(odd) .tile:before {
        border-right: 90vw solid transparent;
        top: -99px
    }
}

@media screen and (max-width:414px) {
    html body main>section.womix__horizontal-module-small .module-wrapper:nth-child(odd) .tile:before {
        border-right: 100vw solid transparent
    }
}

html body main>section.womix__horizontal-module-small .module-wrapper:nth-child(odd) .tile:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -99px;
    width: 0;
    height: 0;
    border-top: 100px solid #fff;
    border-right: 60vw solid transparent
}

@media screen and (min-width:1280px) {
    html body main>section.womix__horizontal-module-small .module-wrapper:nth-child(odd) .tile:after {
        display: none
    }
}

@media screen and (max-width:812px) {
    html body main>section.womix__horizontal-module-small .module-wrapper:nth-child(odd) .tile:after {
        border-right: 90vw solid transparent
    }
}

@media screen and (max-width:414px) {
    html body main>section.womix__horizontal-module-small .module-wrapper:nth-child(odd) .tile:after {
        border-right: 100vw solid transparent
    }
}

html body main>section.womix__reminder-module {
    margin: 200px auto 200px auto;
    text-align: center
}

html body main>section.womix__reminder-module a {
    text-decoration: none;
    display: inline-block;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 20px 25px;
    border: 3px solid #fff;
    background: 0 0;
    -webkit-transition: .3s;
    transition: .3s
}

@media screen and (max-width:450px) {
    html body main>section.womix__reminder-module a {
        padding: 20px 10px
    }
}

html body main>section.womix__reminder-module a h2 {
    display: inherit;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 36px;
    font-size: 5vw;
    line-height: 1.2;
    text-transform: uppercase;
    text-transform: uppercase;
    color: #fff;
    text-align: center
}

@media (max-width:48em) {
    html body main>section.womix__reminder-module a h2 {
        font-size: 2.4em
    }
}

@media (min-width:90em) {
    html body main>section.womix__reminder-module a h2 {
        font-size: 4.5em
    }
}

html body main>section.womix__reminder-module a:hover {
    background: #0083d3;
    -webkit-transition: .3s;
    transition: .3s
}

html body main>section.womix__reminder-module>p {
    max-width: 812px;
    margin: 20px auto;
    text-align: center;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.5em
}

html body main>section.womix__reminder-module form {
    max-width: 450px;
    margin: auto
}

html body main>section.womix__reminder-module form .e-mail-input {
    margin-top: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex
}

html body main>section.womix__reminder-module form .e-mail-input #form1_email {
    padding-left: 10px;
    height: 50px;
    width: 100%;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.5em;
    border: 3px solid #fff;
    background: 0 0;
    border-right: none;
    color: #fff
}

html body main>section.womix__reminder-module form .e-mail-input ::-webkit-input-placeholder {
    color: #fff;
    opacity: 1
}

html body main>section.womix__reminder-module form .e-mail-input :-ms-input-placeholder {
    color: #fff;
    opacity: 1
}

html body main>section.womix__reminder-module form .e-mail-input ::-ms-input-placeholder {
    color: #fff;
    opacity: 1
}

html body main>section.womix__reminder-module form .e-mail-input ::placeholder {
    color: #fff;
    opacity: 1
}

html body main>section.womix__reminder-module form .e-mail-input input#form1_surname,
html body main>section.womix__reminder-module form .e-mail-input label#label_surname {
    background-color: transparent;
    height: 0;
    width: 0;
    visibility: hidden;
    opacity: 0;
    overflow: hidden
}

html body main>section.womix__reminder-module form .e-mail-input button {
    position: relative;
    padding: 0;
    width: 80px;
    border: 3px solid #fff;
    cursor: pointer;
    height: 50px;
    background: 0 0;
    position: relative
}

html body main>section.womix__reminder-module form .e-mail-input button svg {
    height: 40px
}

html body main>section.womix__reminder-module form .e-mail-input button .svg-send,
html body main>section.womix__reminder-module form .e-mail-input button .svg-type {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    opacity: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0
}

html body main>section.womix__reminder-module form .e-mail-input button .svg-send.active,
html body main>section.womix__reminder-module form .e-mail-input button .svg-type.active {
    -webkit-transition: .3s cubic-bezier(.445, .05, .55, .95);
    transition: .3s cubic-bezier(.445, .05, .55, .95);
    opacity: 1
}

html body main>section.womix__reminder-module form .e-mail-input button.is-active {
    -webkit-transition: background .3s cubic-bezier(.445, .05, .55, .95);
    transition: background .3s cubic-bezier(.445, .05, .55, .95);
    background: #0083d3
}

html body main>section.womix__reminder-module form .data-storage {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.5em;
    font-size: 16px;
    color: #fff;
    margin-top: 10px;
    position: relative
}

html body main>section.womix__reminder-module form .data-storage .checkbox {
    position: relative;
    height: 0;
    width: 0
}

html body main>section.womix__reminder-module form .data-storage .checkbox input {
    opacity: 0
}

html body main>section.womix__reminder-module form .data-storage .checkbox input:checked~.custom-checkbox {
    background: #0083d3;
    -webkit-transition: .3s;
    transition: .3s
}

html body main>section.womix__reminder-module form .data-storage .checkbox input:checked~.custom-checkbox:after {
    border-radius: 30px;
    background: #0083d3;
    position: absolute;
    width: 60px;
    height: 60px;
    opacity: 0;
    -webkit-transform-origin: top left;
    transform-origin: top left;
    -webkit-transition: width .4s, height .4s, opacity .3s .2s;
    transition: width .4s, height .4s, opacity .3s .2s
}

html body main>section.womix__reminder-module form .data-storage .checkbox .custom-checkbox {
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    border: 3px solid #fff;
    border-radius: 50%;
    opacity: 1;
    -webkit-transition: .3s;
    transition: .3s;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center
}

html body main>section.womix__reminder-module form .data-storage .checkbox .custom-checkbox:after {
    content: "";
    display: block;
    width: 2px;
    height: 2px;
    border-radius: 50%;
    opacity: 1;
    position: absolute;
    background: 0 0
}

html body main>section.womix__reminder-module form .data-storage label {
    z-index: 1;
    padding-left: 40px
}

html body main>section.womix__reminder-module form .data-storage label a {
    color: #fff
}

html body main>section.womix__reminder-module .success-response {
    position: fixed;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    top: -100%;
    left: 0;
    opacity: 0;
    background-color: #0083d3;
    z-index: 10
}

@media screen and (max-width:736px) {
    html body main>section.womix__reminder-module .success-response {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

html body main>section.womix__reminder-module .success-response.active {
    opacity: 1;
    top: 0;
    -webkit-transition: top .2s cubic-bezier(.445, .05, .55, .95);
    transition: top .2s cubic-bezier(.445, .05, .55, .95)
}

html body main>section.womix__reminder-module .success-response.animated {
    opacity: 1;
    top: 100%;
    -webkit-transition: top .2s cubic-bezier(.445, .05, .55, .95);
    transition: top .2s cubic-bezier(.445, .05, .55, .95)
}

html body main>section.womix__reminder-module .success-response p {
    margin-left: 30px;
    max-width: 100%;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 24px;
    font-size: 2.5vw;
    line-height: 1.5em;
    color: #fff
}

@media (max-width:52em) {
    html body main>section.womix__reminder-module .success-response p {
        font-size: 1.3em
    }
}

@media (min-width:120em) {
    html body main>section.womix__reminder-module .success-response p {
        font-size: 3em
    }
}

@media screen and (max-width:736px) {
    html body main>section.womix__reminder-module .success-response p {
        margin-left: 0;
        margin-top: 30px
    }
}

html body main>section.womix__legal {
    color: #fff;
    padding: 0 50px
}

@media screen and (max-width:812px) {
    html body main>section.womix__legal {
        padding: 0 5vw
    }
}

html body main>section.womix__legal h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 36px;
    font-size: 5vw;
    line-height: 1.2;
    text-transform: uppercase;
    margin-bottom: 30px
}

@media (max-width:48em) {
    html body main>section.womix__legal h1 {
        font-size: 2.4em
    }
}

@media (min-width:90em) {
    html body main>section.womix__legal h1 {
        font-size: 4.5em
    }
}

@media screen and (max-width:414px) {
    html body main>section.womix__legal h1 {
        font-size: 1.3em
    }
}

html body main>section.womix__legal h2 {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 24px;
    font-size: 2.5vw;
    line-height: 1.5em;
    margin-bottom: 20px
}

@media (max-width:52em) {
    html body main>section.womix__legal h2 {
        font-size: 1.3em
    }
}

@media (min-width:120em) {
    html body main>section.womix__legal h2 {
        font-size: 3em
    }
}

html body main>section.womix__legal h3 {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 1.5em;
    line-height: 1.5em;
    margin-bottom: 15px
}

html body main>section.womix__legal a {
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.5em;
    color: #fff;
    word-break: break-all;
    white-space: pre-wrap;
    margin-bottom: 12px
}

html body main>section.womix__legal p {
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.5em;
    margin-bottom: 12px
}

html body main>section.womix__legal p a {
    font-size: 1em
}

html body main>section.womix__legal ul {
    padding-left: 25px;
    list-style-type: disc
}

html body main>section.womix__legal ul li {
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.5em
}

html body main>section.womix__legal ul li a {
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.5em;
    font-size: 1em
}

html body main>section.womix__legal b {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 1.2em;
    line-height: 1em;
    color: #fff;
    font-size: 1em
}

html body main>section.womix__legal .womix__ga-opt-out {
    text-decoration: underline;
    cursor: pointer
}

html body main>section.womix__faq {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start
}

html body main>section.womix__faq h3 {
    padding: 15px 30px 15px 0;
    position: relative;
    cursor: pointer;
    margin: 0
}

html body main>section.womix__faq h3:after {
    position: absolute;
    top: calc(50% - 5px);
    right: 5px;
    content: '';
    width: 8px;
    height: 8px;
    border-width: 2px;
    border-style: solid;
    border-color: #fff #fff transparent transparent;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: all .4s cubic-bezier(.445, .05, .55, .95);
    transition: all .4s cubic-bezier(.445, .05, .55, .95)
}

html body main>section.womix__faq h3.active:after {
    top: calc(50% - 9px);
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg)
}

html body main>section.womix__faq>section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-bottom: 30px;
    width: 100%
}

html body main>section.womix__faq>section div {
    max-height: 0;
    overflow: hidden;
    width: 100%;
    transition: all .6s linear
}

html body main>section.womix__faq>section div.active {
    max-height: 1000px;
}

html body main>section.womix__faq>section div p {
    padding: 20px 10px
}

html body main>* {
    -ms-flex-negative: 0;
    flex-shrink: 0
}

html body footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 100px;
    width: inherit;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding: 30px 0
}

html body footer>div {
    width: 100%
}

html body footer .contact {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 20px 5vw 60px
}

html body footer .contact h3 {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 24px;
    font-size: 2.5vw;
    line-height: 1.5em;
    font-size: 1.7em !important;
    text-align: center;
    color: #fff;
    margin-bottom: 15px
}

@media (max-width:52em) {
    html body footer .contact h3 {
        font-size: 1.3em
    }
}

@media (min-width:120em) {
    html body footer .contact h3 {
        font-size: 3em
    }
}

html body footer .contact ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center
}

html body footer .contact ul li {
    width: 100%;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.5em;
    text-align: center;
    color: #fff;
    padding: 5px
}

html body footer .contact ul li span {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 1.2em;
    line-height: 1em;
    color: #fff;
    font-size: 1em !important
}

html body footer .contact ul li a {
    color: #fff
}

html body footer .contact ul li.half {
    width: auto;
    margin-top: 10px
}

@media screen and (max-width:812px) {
    html body footer .contact ul li.half {
        width: 100%
    }
}

html body footer .contact ul li.half:last-of-type {
    margin-left: 15px
}

@media screen and (max-width:812px) {
    html body footer .contact ul li.half:last-of-type {
        margin-left: 0
    }
}

html body footer .sponsor-section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    border-top: 3px solid #fff;
    border-bottom: 3px solid #fff;
    max-width: 1920px;
    padding: 20px 5vw
}

html body footer .sponsor-section>div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 28%;
    padding: 30px
}

@media screen and (max-width:1024px) {
    html body footer .sponsor-section>div {
        width: 48%
    }
}

@media screen and (max-width:812px) {
    html body footer .sponsor-section>div {
        padding: 0
    }
}

@media screen and (max-width:414px) {
    html body footer .sponsor-section>div {
        width: 100%
    }

    html body footer .sponsor-section>div:not(:last-of-type) {
        margin-bottom: 40px
    }
}

html body footer .sponsor-section>div h3 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 1.2em;
    line-height: 1em;
    color: #fff;
    color: #fff;
    margin-bottom: 20px
}

@media screen and (max-width:414px) {
    html body footer .sponsor-section>div h3 {
        width: 100%;
        text-align: center
    }
}

html body footer .sponsor-section>div .womix__image-wrapper {
    padding: 20px 0;
    width: 100%;
    max-width: 350px
}

@media screen and (max-width:812px) {
    html body footer .sponsor-section>div .womix__image-wrapper {
        padding: 10px 0
    }
}

html body footer .sponsor-section>div .womix__image-wrapper img {
    display: block;
    width: 100%;
    max-width: 100%
}

html body footer .sponsor-section>div .womix__image-wrapper h3 {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 24px;
    font-size: 2.5vw;
    line-height: 1.5em;
    line-height: 1.2em
}

@media (max-width:52em) {
    html body footer .sponsor-section>div .womix__image-wrapper h3 {
        font-size: 1.3em
    }
}

@media (min-width:120em) {
    html body footer .sponsor-section>div .womix__image-wrapper h3 {
        font-size: 3em
    }
}

html body footer .sponsor-section>div .womix__image-wrapper h3 span {
    font-size: 20px;
    line-height: 24px
}

html body footer .sponsor-section>div .womix__image-wrapper a {
    text-decoration: none
}

html body footer .sponsor-section>div.further-sponsors .womix__image-wrapper:first-of-type {
    max-width: 100%
}

html body footer .sponsor-section>div.further-sponsors .womix__image-wrapper:nth-of-type(2),
html body footer .sponsor-section>div.further-sponsors .womix__image-wrapper:nth-of-type(3) {
    max-width: 47%
}

@media screen and (max-width:414px) {

    html body footer .sponsor-section>div.further-sponsors .womix__image-wrapper:nth-of-type(2),
    html body footer .sponsor-section>div.further-sponsors .womix__image-wrapper:nth-of-type(3) {
        width: 45%
    }
}

html body footer .sponsor-section>div.further-sponsors .womix__image-wrapper:nth-of-type(3) {
    margin-top: .7vw
}

@media screen and (max-width:1024px) {
    html body footer .sponsor-section>div.further-sponsors .womix__image-wrapper:nth-of-type(3) {
        margin-top: 1.3vw
    }
}

@media screen and (max-width:414px) {
    html body footer .sponsor-section>div.further-sponsors .womix__image-wrapper:nth-of-type(3) {
        margin-top: 2.7vw
    }
}

html body footer .sponsor-section>div.further-sponsors .womix__image-wrapper:nth-of-type(4) {
    max-width: 65%
}

html body footer .sponsor-section>div.organisators {
    width: 30%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    flex-direction: column;
}

@media screen and (max-width:1024px) {
    html body footer .sponsor-section>div.organisators {
        width: 100%
    }
}

html body footer .sponsor-section>div.organisators h3 {
    width: 100%;
    text-align: center;
}

html body footer .sponsor-section>div.organisators .womix__image-wrapper {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    max-width: 450px
}

html body footer .sponsor-section>div.organisators .womix__image-wrapper:nth-of-type(1) {
    width: auto;
    max-width: 100%
}

html body footer .sponsor-section>div.organisators .womix__image-wrapper:nth-of-type(2) {
    width: auto;
    max-width: 100%
}

html body footer .sponsor-section>div.organisators .womix__image-wrapper:nth-of-type(3) {
    width: auto;
    max-width: 100%
}

html body footer .sponsor-section>div.organisators .womix__image-wrapper:nth-of-type(3) a {
    color: #fff
}

html body footer .sponsor-section>div.organisators .womix__image-wrapper:nth-of-type(3) h4 {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 24px;
    font-size: 2.5vw;
    line-height: 1.5em;
    font-size: 1.2em !important
}

@media (max-width:52em) {
    html body footer .sponsor-section>div.organisators .womix__image-wrapper:nth-of-type(3) h4 {
        font-size: 1.3em
    }
}

@media (min-width:120em) {
    html body footer .sponsor-section>div.organisators .womix__image-wrapper:nth-of-type(3) h4 {
        font-size: 3em
    }
}

html body footer .sponsor-section>div.cooperation {
    width: 30%;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0px auto 0;
    flex-direction: column;
}

html body footer .sponsor-section>div.cooperation h3 {
    width: 100%;
    text-align: center
}

@media screen and (max-width:812px) {
    html body footer .sponsor-section>div.cooperation {
        width: 80%
    }
}

@media screen and (max-width:414px) {
    html body footer .sponsor-section>div.cooperation {
        width: 100%
    }
}

html body footer .sponsor-section>div.cooperation .womix__image-wrapper {
    width: auto;
    padding: 0 20px;
    max-width: 350px;
    margin-top: 20px
}

html body footer .sponsor-section>div.cooperation .womix__image-wrapper:last-of-type {
    max-height: 110px
}

html body footer .sponsor-section>div.cooperation .womix__image-wrapper:last-of-type a {
    max-height: inherit
}

html body footer .sponsor-section>div.cooperation .womix__image-wrapper:last-of-type img {
    width: auto;
    max-height: inherit
}

.main-sponsors h3{
    width: 100%;
    text-align: center;
}

html body footer .legal-section {
    padding: 50px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

html body footer .legal-section a {
    padding: 10px 20px;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.5em;
    font-weight: 700;
    color: #fff;
    text-decoration: none;
    text-align: center
}

@media screen and (max-width:750px) {
    html body footer .legal-section {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column
    }
}

.impressionNew {
  display: block;
}

.divRow > ul {
  margin: 0px;
  padding: 15px 0px 15px 0px;
  width: 100%;
}

.divRow > ul > li {
  list-style-type: none;
  margin: 10px;
  padding: 5px;
  border-width: 2px;
  display: inline-block;
  vertical-align: top !important;
  /*width: 20em;*/
}

.itemDiv{
  margin:  0px;
  padding:  0px;
  height: 25em;
  width: 25em;
}

.App-header {
  height: 50px;
  padding: 10px 0px 10px 0px;
  /* width: 40%; */
  margin: 0px;
  text-align: center;
  text-transform: uppercase;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 36px;
  line-height: 1.2;
  color: #fff;
}

.divRow{
  display: block;
  margin-left: calc((100% - 81.5em)/2);
  margin-right: calc((100% - 81.5em)/2);
}

.mainDiv{
    margin-top: 10em;
}


.item-header {
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 25px;
    line-height: 1.2;
    color: #fff;
}

.item-sub-header {
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 15px;
    line-height: 1.2;
}

.team-names {
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.2;
    color: #fff;
}

@media screen and (max-width:81.5em) {
    .divRow{
        display: block;
        margin-left: calc((100% - 54.5em)/2);
        margin-right: calc((100% - 54.5em)/2);
    }
}

@media screen and (max-width:54.5em) {
    .divRow{
        display: block;
        margin-left: calc((100% - 27em)/2);
        margin-right: calc((100% - 27em)/2);
    }
}

#tempImg{
    height: 100%;
    width: 100%;
    display: block;
}

.pdfFrame{
    height: 22em;
    width: 25em;
    display: block;

}

.itemDiv-pdfRow{
    margin:  0px;
    padding:  0px;
    height: 30em;
    width: 25em;
  }

