
.primary-color-lightblue-background{
    background-color: #0cbabe;
}

.primary-color-blue-background{
    background-color: #0083d3;
}

.primary-color-lightgreen-background{
    background-color: #00E3A7;
}

.primary-color-lightblue-foreground{
    color: #0cbabe!important;
}

.primary-color-blue-foreground{
    color: #0083d3!important;
}

.primary-color-lightgreen-foreground{
    color: #00E3A7!important;
}

.text-center{
    text-align: center;
}

.mt-0{
    margin-top:0!important
}

.mb-0{
    margin-bottom: 0!important;
}

/* General Structure */

body {
    position: relative !important;
    overflow-x: hidden !important;
}
.height100vh
{
    height: 100vh !important;
}
.padding-top
{
    padding-top: 100px !important;
}

.container-fluid {
    /* height: calc(100vh - 11.9rem); */
  }

.container-fluid {
    padding: 0px !important;
    position: relative !important;
    float: left !important;
    width: 100% !important;
}

.container-limit {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
}

.container-limit.wide-limit
{
    max-width: 1440px;
}



.container-limit.extra-limit {
    max-width: 1440px;
}

.container-limit.my-limit {
    max-width: 900px;
}

.container-no-limit {
    width: 100% !important;
    max-width: 100% !important;
}

.container{
    width: 100%;
    position: relative;
}

.container-color {
    background-color: #f5f3f7 !important;
}

.content-base{
    min-height: calc(100vh -  (25vh + 85px));
    width: 100%;
    display: flex;
    justify-content: center;
}

/* General Structure End*/


.app-banner-wrapper{
    position: relative;
    /* float: left; */
    width: calc(100% - 4rem);
    height: 5rem;
    margin: 1rem 2rem;
    z-index: 999;
}

.banner-grid{
    width: 100%;
    display: grid;
    grid-template-columns: 15rem auto 3rem ;
}


.banner-logo {
    position: relative;
    float: left;
    width: 15rem;
    height: 55px;
    margin: 15px 0 15px 0;
    border-right: 2px solid #999;
}

.banner-logo img {
    position: relative;
    float: left;
    width: 40px;
    max-width: 40px;
    height: auto;
    margin-right: 1rem;
    /* left: 10%;
    transform: translateX(-10%) */
}

.banner-logo h1 {
    font-family: "Open Sans";
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.5em;
    font-size: 13px;
    position: relative;
    float: left;
    width: 70%;
    margin-top: 12px;
}

.banner-logo h1 span {
    font-weight: bold
}

.banner-logo a {
    color: black;
}


@media only screen and (max-width: 1024px) {
    .banner-logo {
        /* width: 25%; */
    }
}

.margin-top-5{
    margin-top:3%;
}

.content {
    position: relative;
    float: left;
    width: 100%;
    min-height: calc(100vh - (25vh + 85px));
    margin-top: 16px;
    margin-bottom: 30px;
    /* overflow-y: auto; */
}

.homepage{
    margin-bottom: 0px;
}



.menu-right{
    grid-column: 3;
    cursor:pointer;
    height: 3rem;
    width: 3rem;
    float: right;
    margin: 1rem 0rem;
}


.main-content {
    position: relative;
    display: block;
    width: 100%;
    min-height: calc(100vh - (25vh + 85px));
    margin-top: 16px;
    margin-bottom: 30px;
    /* overflow-y: auto; */
}

.tiles-container{

    display: flex;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: wrap;
    
}

.tutorials-tiles{
    display: flex;
    justify-content: center;
    min-height: 30rem;
    flex-wrap: wrap;
    /* margin-bottom:5rem; */
}

.tutorial-landing{

    width: 100%;
    display: flex;
    justify-content: center;
}

.narrow-tile-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-self: center;
}


.header-wrapper.entry
{
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-banner-dropdown{
    height: 25rem;
    width: 18rem;
    position: absolute;
    right: 2.25rem;
    top: 4.5rem;
    padding: 1rem 1rem 0rem 1rem;
    background: #EEE;
    z-index: 100000;
}

.breadcrumb-dropdown{
    height: 10.5rem;
    width: 15rem;
    position: absolute;
    top:5rem;
    left:20rem;
}

.app-banner-overlay{

}

.app-banner-dropdown li{
    margin: 0 0.5rem 0.5rem 0.5rem;
    border-bottom: 0.5px solid #333;
    padding-bottom:0.25rem;
}

.app-banner-dropdown.breadcrumb-dropdown li:last-child{
    border:0;
}

.app-banner-dropdown a{
    display: block;
    color: #000;
    font-size: 1.2rem;
    padding: 0.25rem 0rem;
    text-decoration: none;
}


.app-banner-dropdown a:hover{
font-weight: 600;
   color: #000;
   text-decoration: none;
}

.app-banner-dropdown .logout{
    width: 100%;
    text-align: center;
    font-weight: bold;
}

.breadcrumb-main{
    height: 100%;
    width: calc(100% - 5rem);
    padding: 1.2rem 0rem 0rem 0rem;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.25rem;
}

.breadcrumb-main a{
    float: left;
    width: auto;
    margin: 0.5rem 0rem 0.5rem 0.5rem;
    color: #333;
    font-weight: bold;
}

.breadcrumb-main a:hover{
    color: #333;
    text-decoration: none;
}

.breadcrumb-main span{
    margin-right:0.4rem;
}


.font-main-header{
    font-size: 1.75rem;
    font-weight: bold;
    /* background-image: linear-gradient(to left, blue 50%, red 0%); */
    color:#000;
    text-align: center;
    font-weight: 600;
      
}

.font-sub-header,
div.homepage.sub-header.font-sub-header,
div.main-content .font-sub-header{
    font-size: 1.25rem;
    font-weight: normal!important;
}

.homepage.header1{
    width: 90%;
    height: auto;
    margin: 1% 5%;

}

.homepage.header2{
    max-width: 75rem;
    width: 90%;
    margin: 1rem auto;
}

.task-archive-header{
    width: 60rem!important;
}

.homepage .path-tiles,.subpath-tiles{
        max-width: 1440px;
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        /* float: left; */
        margin: auto;
        /* height: 100%; */
        padding: 0px 15px;
        /* padding-bottom: 30px; */
}

.subpath-tiles{
    max-width: 1440px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    /* float: left; */
    margin: auto;
    /* height: 100%; */
    padding: 0px 15px;
    /* padding-bottom: 30px; */
}

.subpath-tiles{
    /* width: 90%; */
}

.homepage .homepage-tile{
    /* width: 25%; */
    width: 23rem;
    height: 23rem;
    float: left;
    margin: 1rem 2rem;
}

.homepage-tile.layout{
    position: relative;
}

.homepage-tile.layout .layout-wrapper{
    position: relative;
    width: 23rem;
    height: 23rem;
}

.homepage-tile.layout .layout-wrapper .tile-text{
    font-size: 1.1rem;
    line-height: 1.2rem;
    color: white;
    margin-top: -27.5%;
    margin-left: 40%;
    width: 13rem;
}

.homepage-tile.layout .layout-wrapper .tile-text:hover{
    color: white;
    text-decoration: none!important;
}


.homepage-tile:hover{
    transform: scale(1.05);
    text-decoration: none!important;

}

/* .homepage .homepage-tile.image{
    width: 100%;

} */


.subpath-tile{
   float: left;
   margin: 1rem;
   height: 25rem;
   width: 25rem;
   
}

.subpath-tile.tutorial{
    margin-top:2rem!important;
}

.subpath-tile .image{
    width: calc(100% * 2);
    
}


a.subpath-tile:hover{
 text-decoration: none;
 transform: scale(1.05);
}

.subpath-tile .tile-wrapper{
    background:none;
    height: fit-content;
    width: 100%;
    display: block;
}

.subpath-tile .tile-wrapper .row2{
    position: relative;
    /* background:rgb(12,186,190); */
    grid-row: 1;
    height: 13rem;
    margin-left: 17.25%;
    margin-top: -5px;
}


.tile-wrapper .row2 .tile-sub-header,
.tile-wrapper .row2 .tile-header,
.tile-wrapper .row2 .tile-descritpion,
.tile-wrapper .row2 .tile-status{
    display: block;
    width: 100%;
    color: white;
    text-decoration: none;
    
} 

.tile-wrapper .row2 .tile-sub-header:hover,.tile-wrapper .row2 .tile-header:hover{
    text-decoration: none!important;
}

.tile-wrapper .row2 .tile-sub-header{
    font-size: 1rem;
    font-weight: 600;
    padding: 1rem 1rem 0rem 1rem;
}

.tile-wrapper .row2 .tile-header{
    font-size: 1.75rem;
    font-weight: bold;
    padding: 0rem 1rem;
    line-height: 2rem;
    
}

.tile-wrapper .row2 .tile-header.fix-height-3{
    height: 6rem!important;
}

.tile-wrapper .row2 .tile-descritpion{
    font-size: 1rem;
    line-height: 1.2rem;
    font-weight: normal;
    padding: 0.5rem 1rem;
}

.tile-wrapper .row2 .tile-status{
    position: absolute;
    bottom: 10px;
    font-size: 1rem;
    font-weight: 600;
    padding: 1rem 1rem 0rem 1rem;
}

@media only screen and (max-width: 1440px) {
    .subpath-tile.tutorial{ 
        float: left;
        margin: 2%;
        height: 20rem;
        width: 24rem;
        
     }

     .subpath-tile .tile-wrapper .row2{
        /* background:rgb(12,186,190); */
        grid-row: 1;
        height: 13rem;
        margin-left: 17.25%;
        margin-top: -5px;
    }
}

.homepage-bottom-option{
    width: 100%;
    margin: 0;
    margin-left:calc((100% - 75rem)/2);
    font-size: 1.5rem;
    font-weight: 600;

}
@media only screen and (max-width: 1325px) {
    .homepage-bottom-option{
        margin-left:calc((100% - 50rem)/2);
    
    }

    .homepage.header2{
        max-width: 50rem;
        width: 90%;
    }
  
    
  }

  @media only screen and (max-width: 895px) {
    .homepage-bottom-option{
        margin-left:10%;
    
    }

    .homepage.header2{
        max-width: 50rem;
        width: 80%;
    }
  
    
  }

.homepage-bottom-option span{
    cursor: pointer;
}


/* AI school page*/
.aischool-tiles{
    /* width:calc(75% + 12rem) ; */
    width: 100%;
    display: flex;
    justify-content: center;
}

.aischool-tile{
    float:left;
    width:22rem;
    height: 15rem;
    margin:1rem 2rem 1rem 2rem;
    background: #DDD;
}

.aischool-tile.link:hover{
    transform: scale(1.05);
}

.aischool-tile:hover{
    text-decoration: none!important;
}

.aischool-tile-inside{
    
    min-height: 12rem;
    width:100%;
    background: #DDD;
}


.aischool-tile .tile-sub-header,
.aischool-tile .tile-header,
.aischool-tile .tile-footer{
    display: block;
    width: 100%;
    color: #333;
    text-decoration: none;
    text-align: center;
    
} 

.aischool-tile .tile-sub-header:hover,
.aischool-tile .tile-header:hover,
.aischool-tile .tile-footer:hover{
    text-decoration: none!important;
}

.aischool-tile .tile-sub-header{
    font-size: 1.2rem;
    font-weight: 600;
    padding: 1rem;
}

.aischool-tile .tile-header{
    font-size: 1.5rem;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    padding: 1rem;
    height: 7.5rem;

}

.aischool-tile .tile-header.last-tile{
    height: 8.5rem;
    display: flex;
    align-content: center;
    flex-direction: column;
    color:#0cbabe;
}

.aischool-tile .tile-header.last-tile div:nth-child(2){
    font-size: 1.2rem;
    font-weight: 600;
}

.aischool-tile .tile-footer{
    font-size: 1.2rem;
    font-weight: 600;
    padding: 1rem;

}

/*AI school page end */


.test-container{
    width: 100%;
    display: flex;
    background-color: rosybrown;
    justify-content: center;
    height: 40rem;
}


.image-container{
    width: 30%;
    height:50em;
    background: chartreuse;
    grid-template-rows: auto 1rem auto;
    
}

.image-wrapper{
    grid-row: 0;
    display: inline;
    position:relative
}

.image-container .imagefile{
    /* transform: translateX(0rem); */
    display: block;
    width: 50%;
    float: right;
    
}

.secondrow{
    grid-row: 3;
    display: block;
    float: none;
    width: 100%;
    height: 10rem;
    background: royalblue;
}


/*  tutorials */


/* - progress bar */
.progress-table-bar {
    position: relative;
    float: left;
    width: calc(100%);
    margin-top: 10px;
    height: 2rem;
}


.progress {
    position: relative;
    float: left;
    width: 100%;
    height: calc(2rem - 2px)!important;
    background-color: #fff!important;
    border-radius: 0.3rem;
    border: 1px solid #AAAAAA;
}


.progress-bar {
    border-radius: calc(0.3rem - 1px);
    background-color: #EEE!important;
    height: 2rem;
}

.progress-div{
    position: relative;
}


.progress-bar span {
    color: #AAA;
    text-align: left;
    vertical-align: middle;
    padding-left: 10px;
    font-size: 1rem;
    font-weight: 600;

}

.progress-div .progress-span {
    color: #AAA;
    text-align: left;
    vertical-align: middle;
    /* padding-left: 10px; */
    font-size: 1rem;
    font-weight: 600;
    position: absolute;
    top: 0.215rem;
    left:0.5rem;
    
}

/* - progress bar end */


.grid-box {
    float: left;
    width: 98%;
    display: -webkit-flex;
    -webkit-align-items: flex-start;
    -moz-align-items: center;
    -moz-flex-direction: row;
    flex-direction: row;
    flex-wrap: wrap;
    flex-flow: row wrap;
    align-content: flex-start;
    justify-content: center;
    margin:0 1% 0 1%;
  }
  .grid-box.multiple-choice
  {
    margin-top: 50px;
  }
  .job-box {
    height: auto;
    min-height: 35rem;
    max-height: 40rem;
    width: 320px;
    background-color: #EEE;
    -webkit-transform: scale(.95);
    transform: scale(.95);
    margin-bottom: 2rem;
    transition: .3s linear all;
    border-radius: 5px;
    /* box-shadow: 0px 0px 36px -15px rgba(0, 0, 0, 1); */
    cursor: pointer;
  }

  .dog-job-box{
    height: 45rem;
    max-height: 50rem;
  }

  .aisociety-job-box{
    height: 42rem;
    max-height: 50rem;
    margin-bottom: 3rem;
  }


  .job-box .content-box span.title
  {
    font-size: 1.75rem;
    line-height: 1.75rem;
    font-weight: bold;
    height: 5rem;
  }

  .dog-job-box.job-box .content-box span.title
  {
    height: 4rem;
  }

  @media only screen and (min-width: 2000px) {

    .dog-job-box{
        width: 375px;
    }
    .narrow-tile-container{
        width: 100%;
    }

    .job-box .content-box span.title{
        font-size: 1.75rem!important;
    }
    
    
  }

  @media only screen and (min-width: 1661px) and (max-width: 2000px) {

    .dog-job-box{
        height: 46rem;
        width: 20%;
    }

    .job-box .content-box span.title{
        font-size: 1.75rem!important;
    }
    .narrow-tile-container{
        width: 1200px;
    }

    .aisociety-job-box{
        width: 20%;
    }
  }

  @media only screen and (max-width: 1660px) {
    .dog-job-box{
        width: 20%;
    }

    .job-box .content-box span.title{
        font-size: 1.75rem!important;
    }

    .narrow-tile-container{
        width: 1200px;
    }

    .aisociety-job-box{
        width: 20%;
    }
    
  }

  @media only screen and (max-width: 1610px) {

    .dog-job-box{
        height: 46rem;
        width: 20%;
    }
    .job-box .content-box span.title{
        font-size: 1.65rem!important;
    }

    .dog-job-box .content-box span.title{
        font-size: 1.65rem!important;
    }

    .narrow-tile-container{
        width: 1200px;
    }

    .aisociety-job-box{
        width: 20%;
    }
    
  }

  @media only screen and (max-width: 1580px) {
    .dog-job-box.job-box{
        width: 20%;
    }

    .dog-job-box{
        height: 46rem;

    }

    .aisociety-job-box{
        width: 20%;
    }

  }


  @media only screen and (max-width: 1520px) {

    .dog-job-box{
        height: 47rem;

    }

    .job-box .content-box span.title{
        font-size: 1.5rem!important;
    }

    .aisociety-job-box .content-box span.title{
        font-size: 1.6rem!important;
        height: 3.5rem;
    }

    .narrow-tile-container{
        width: 1200px;
    }

    .aisociety-job-box{
        width: 20%;
    }
 
  }

  @media only screen and (max-width: 1400px) {

        .aisociety-job-box{
            width: 20%;
        }

        .dog-job-box .content-box span.title{
            font-size: 1.4rem!important;
            height: 3.5rem;
        }

        .dog-job-box.job-box{
            width: 265px;
            height: 48rem;
        }
    
  }

  @media only screen and (max-width: 1355px) {
        .dog-job-box .content-box span.title{
            font-size: 1.5rem!important;
            height: 3.5rem;
        }

        .dog-job-box.job-box{
            width: 28%;
            height: 48rem;
        }

        .aisociety-job-box{
            width: 28%;
        }

    }

    @media only screen and (max-width: 1200px) {

        .dog-job-box .content-box span.title{
            font-size: 1.6rem!important;
            height: 3.5rem;
        }

        .dog-job-box.job-box{
            width: 300px;
            height: 48rem;
        }

        .aisociety-job-box{
            width: 300px;
        }


    }


  @media only screen and (max-width: 800px) {

    .job-box{
        width:500px;
    }


    .dog-job-box.job-box{
        width: 500px;
        height: 42rem;
    }


    .job-box .content-box span.title{
        font-size: 1.5rem!important;
    }

    .narrow-tile-container{
        width: 90%;
    }


    
  }

  .job-box:hover
  {
    transform: scale(1);
  }

  .job-box.margin-top-10{
      margin-top: 2em!important;
  }

  .grid-box .box-inside, 
  .grid-box .grid-1 
  {
    position: relative;
    float: left;
    width: 100%;
  }
  .grid-box .grid-2 {
    position: relative;
    float: left;
    width: 50%;
  }
  .grid-box .grid-3 {
    position: relative;
    float: left;
    width: 33.33333%;
  }
  .grid-box .grid-4 {
    position: relative;
    float: left;
    width: 25%;
  }
  .grid-box .grid-5 {
    position: relative;
    float: left;
    width: 20%;
  }
  .grid-box .grid-6 {
    position: relative;
    float: left;
    width: 16.66667%;
  }
      /* responsive */
      @media only screen and (max-width: 1100px) {
          .grid-box .grid-5
          {
              width: calc(calc(100% / 4) - 25px);
          }
      }
      @media only screen and (max-width: 990px) {
          .grid-box .grid-4
          {
              width: calc(calc(100% / 3) - 15px);
          }
          .grid-box .grid-5
          {
              width: calc(calc(100% / 3) - 25px);
          }
      }
      @media only screen and (max-width: 890px) {
          .grid-box .grid-3
          {
              width: calc(calc(100% / 2) - 15px);
          }
          .grid-box .grid-2
          {
              width: 100%;
          }
      }
      @media only screen and (max-width: 720px) {
          .grid-box .grid-5
          {
              width: calc(calc(100% / 2) - 25px);
          }
          .grid-box .grid-4
          {
              width: calc(calc(100% / 2) - 15px);
          }
      }
      @media only screen and (max-width: 570px) {
          .grid-box .grid-3
          {
              width: 100%;
          }
          .grid-box .grid-5
          {
              width: 100%;
          }
          .grid-box .grid-4
          {
              width: 100%;
              margin-right: 0px;
          }
      }

  .job-box .image-box
  {
    position: relative;
    float: left;
    width: 50%;
    margin: -4rem 25% 0 25%;
    height: 7.5rem;
  }

  .job-box .image-box.ai-scoiety
  {
    width: 60%;
    margin: -3rem 20% 0 20%;
  }


  .job-box .image-box img
  {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
    /* backdrop-filter: blur(10px); */
  }
  .job-box .content-box
  {
    position: relative;
    float: left;
    width: 100%;
    height: 300px;
    padding: 20px;
    margin-top: 1rem;
  }
  .job-box .content-box span.chapter,
  .job-box .content-box span.title
  {
    position: relative;
    float: left;
    width: 100%;
    font-size: 1rem;
    cursor: pointer;
    padding: 0px 0px 0px 0px;
  }

  .job-box .content-box span.chapter.chapter-padding{
    padding: 1rem 0 0.5rem 0!important;
  }






.job-box .content-box .section
{
  position: relative;
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0px;
  font-size: 14px;
  font-weight: 300;
  padding: 10px 0px;
}
.job-box .content-box .question-section
{
  position: relative;
  display: block;
  /* height: 150px; */

  width: 100%;
  margin-top:0.5rem;
  padding: 20px 0;
  /* padding-top: 1rem; */

  /* overflow-x: hidden;
  overflow-y: auto; */
}
.job-box .content-box .question-section .question span
{
  font-weight: 600;
  white-space: pre-line;
  
}
.job-box .content-box .question-section .question span:hover
{
  /* color: transparent;
  background: -webkit-gradient(linear, left top, left bottom, from(#0083d3), color-stop(51%, #0cbabe), to(#15e4ae));
  background: -webkit-linear-gradient(top, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
  background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
  -webkit-background-clip: text; */
  font-weight: bold;
}
.job-box .content-box .question-section .question .line
{
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #e3e5e6;
  opacity: 0.5;
  height: 1px;
  transition: .3s linear all;
}
.job-box .content-box .question-section .question:hover .line
{
  opacity: 1;
}
.job-box .content-box .question-section .question
{
  position: relative;
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid #BBB;
}

.content.question {
    /* min-height: calc(calc(100vh - 82px) - 30vh); */
    min-height: calc(100vh - (25vh + 85px));
    margin-top: 0;
    height: auto;
}

/* - video task */



.task-item-lightheader{
    font-size: 1.5rem;
    font-weight: lighter;
    opacity: 0.4;
}

.task-item-mainheader{
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0.25rem 0 0.5rem 0;
}

.task-item-mainheader>a{
    
}


/* Multiple Question Video */
.multiple-choise-video {
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    min-height: 560px;
}

.intro-section {
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    min-height: 30rem;
}

.progressBar {
    position: relative;
    float: left;
    width: 100%;
    height: 30px;
    margin-bottom: 30px;
}

.multiple-choise-video .video-wrapper,
.submit-idea-video .video-wrapper {
    position: relative;
    float: left;
    width: 100%;
    height: 500px;
    margin: 1.5rem 0;
}

.multiple-choise-video .video-wrapper iframe,
.submit-idea-video .video-wrapper iframe {
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
}

.multiple-choise-video .button-video,
.submit-idea-video .button-video {
    position: relative;
    float: left;
    width: 100%;
    height: 50px;
}

.multiple-choise-video .button-video button,
.submit-idea-video .button-video button,
.intro-section .button-video button {
    position: relative;
    width: 12.5rem;
    height: 2.5rem;
    background-color: #EEE;
    color:#AAA;
    border: none;
    border-radius: 0.25rem;
    outline: none;
    cursor: pointer;
    font-size: 17px;
    font-weight: bold;
}


.submit-idea-video .button-video button {
    transform: translateX(-50%);
    float: left;
    left: 50%;
}

.multiple-choise-video .button-video button:first-child {
    float: left;
    left: 0;
}

.multiple-choise-video .button-video button:last-child,
.intro-section .button-video button:last-child {
    float: right;
    right: 0;
}

/* - video task end */

/* - quiz task */
.tittle {
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    padding: 10px 30px;
    margin-bottom: 20px;
}

.question-wrapper .tittle{
    font-size: 1.2rem;
}

.question-wrapper .tittle.fail
{
  /* background-color: #e74c3c;
  color: #fff; */
  border-bottom: 1px solid red;
}
.question-wrapper .tittle.success
{
  /* background-color: #15e4ae;
  color: #fff; */
  border-bottom: 1px solid #15e4ae;
}

.tittle.no-padding-sides{
    padding-left: 0px;
    padding-right: 0px;
}


.question-wrapper .tittle .title-iframe {
    position: relative;
    float: left;
    width: 50%;
}

.question-wrapper .tittle .title-iframe iframe {
    width: 300px;
    height: 300px;
}

.tittle>span {
    position: relative;
    float: left;
    width: 100%;
    white-space: pre-line;
    font-size: 1.2rem;
}

.question-wrapper .tittle span.withFrame {
    width: 50%;
}

.question-wrapper .answers {
    position: relative;
    float: left;
    width: 100%;
    height: auto;
}

.question-wrapper .answers .inside-answers {
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    background-color: #DDD;
    /* box-shadow: 0px 0px 36px -15px rgba(0, 0, 0, 1); */
    margin-bottom: 10px;
    transition: .4s linear all;
    cursor: pointer;
}

.question-wrapper .answers .inside-answers.disabled{
    cursor: auto;
}

.question-wrapper .answers .inside-answers.iframe-answer {
    height: auto;
}

.question-wrapper .answers .inside-answers.iframe-answer .answer-letter {
    height: 300px;
}

.question-wrapper .answers .inside-answers.iframe-answer .answer-content {
    height: 300px;
    padding: 0;
}

.question-wrapper .answers .inside-answers.iframe-answer .answer-content iframe {
    position: absolute;
    float: left;
    width: 300px;
    height: 280px;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 10px;
}

/* .question-wrapper .answers .inside-answers.iframe-answer:hover, */
.question-wrapper .answers .inside-answers.iframe-answer.active {
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    /* box-shadow: 0px 0px 36px -15px rgba(0, 0, 0, 1); */
    margin-bottom: 10px;
    background-color: #0083d3;
}

.question-wrapper .answers .inside-answers input {
    opacity: 0;
    width: 0;
    height: 0;
    display: none;
}

.question-wrapper .answers .inside-answers .answer-letter {
    /* position: relative; */
    position: absolute;
    float: left;
    width: 3rem;
    height: 100%;
    /* background-color: #0083d3; */
    transition: .5s linear all;
}

.question-wrapper .answers .inside-answers .answer-letter span {
    position: absolute;
    float: left;
    color: #fff;
    font-size: 1.5rem;
    font-weight: bold!important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}


.question-wrapper .answers .inside-answers .answer-content {
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
    padding-right: 10px;
}

.question-wrapper .answers .inside-answers .answer-content>span, 
.question-wrapper .answers .inside-answers .answer-content .intro {
    position: relative;
    float: left;
    width: calc(100% - 4rem);
    color: #111;
    margin-left: 4rem;
    padding: 20px 0px;
}

.question-wrapper .answers .inside-answers .answer-content .intro.answer-text p{
    margin-bottom: 0;
}
/* .question-wrapper .answers .inside-answers:hover, */
.question-wrapper .answers .inside-answers.active {
    /* box-shadow: 0px 0px 36px -15px rgba(0, 0, 0, 1); */
    /* background-color: #0083d3; */
    border: 2px solid #888;
}

.question-wrapper .answers .inside-answers:hover span,
.question-wrapper .answers .inside-answers.active span {
    /* color: #fff; */
    font-weight: bold;
}

.question-wrapper .answers .inside-answers.disabled:hover span{
    font-weight: normal;
}

.question-info {
    margin-top: 1rem;
    position: relative;
    float: left;
    width: 100%;
    height: 25px;
}


.question-info span {
    position: relative;
    float: left;
    font-size: 1.25rem;
}

.question-info.error span {
    color: #e74c3c;
}

.question-info .tooltip-span {
    float: left !important;
}

#multiple-question .tooltip-inner {
    background-color: #0cbabe;
}

.wrong-answer{
    background-color: #999999!important;
}


.correct-answer-tutorial{
    background-color: #0cbabe!important;
}

.correct-answer-quiz{
    background-color: #00E3A7!important;
}

.message-correct{
    color: green!important;
}

.message-wrong{
    color: red!important;
}

.message-partial{
    /* color: slateblue; */
}

.message-more{
    /* color:goldenrod; */
}

.message-answers{
    /* color: gold; */
}

.points-panel-wrapper{
    position: absolute;
    right: -12rem;
    top: -3.75rem;
    display: flex;
    width: 10rem;
    flex-wrap: wrap;
    
}

.points-panel-wrapper span{
    margin-left: 3.5rem;
    color: #e7e6e6;
    font-weight: 600;
    font-size: 1.75rem;
}

.points-panel-wrapper .points-panel{
    background-color: #e7e6e6;
    border-radius: 8px;
    text-align: right;
    padding: 5px 10px;
    width: 10rem;
    color: white;
    font-size: 2rem;
    font-weight: bold;
}

/* -- action buttons */


.button-wrapper {
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    left: 50%;
    margin: 2rem 0 1rem 0;
    transform: translateX(-50%);
    max-width: 900px;
}

.button-wrapper button {
    position: relative;
    width: 12.5rem;
    height: 2.5rem;
    border-radius: 0.25rem;
    border: none;
    cursor: pointer;
    outline: none;
    background-color: #EEE;
    color:#999;
    font-size: 1.2rem;
    font-weight: bold;
}

.button-wrapper button:hover{
    color:#333!important;
}

.login-btn{
    font-weight: bold;
    width: auto;
    font-size: 1.25rem;
    margin: 1.6rem 0.5rem;
    color:#333;
    transform: translateX(-200%);
    display:block;

}

.login-btn:hover{
    text-decoration: none;
    color: #333!important;
}


.button-wrapper button.firstBtn {
    float: left;
}

.button-wrapper button.secondBtn {
    position: absolute;
    float: left;
    left: 50%;
    transform: translateX(-50%);
}

.button-wrapper button.thirdBtn {
    float: right;
}

/* -- action buttons end */

/* - quiz task end */



/*Tutorials end*/


/* definition */

.definition-panel-wrapper{
    width: 95%;
    height:fit-content;
    margin: 1rem 2.5% 3rem 2.5%;
    

}

.definition-image-wrapper{
    display: block;
    min-width: 868px;
    width: 868px;
    height: auto;
    margin: 0 auto;
    position: relative;

}

.definition-panel-wrapper .definition-image-wrapper img{
    width: 768px;
    height: auto;
}

.definition-panel-wrapper .definition-image-wrapper .item1{
    position: absolute;
    top:205px;
    left:20px;
    /* top:33.4%;
    left:-10%; */
}

.definition-panel-wrapper .definition-image-wrapper .item2{
    position: absolute;
    top:140px;
    left:550px;
    /* top:22.75%;
    left:73.5%; */
}

.definition-panel-wrapper .definition-image-wrapper .item3{
    position: absolute;
    top:272px;
    left:750px;
    /* top:47.5%;
    left:100%; */
}

.definition-panel-wrapper .definition-image-wrapper .item4{
    position: absolute;
    top:450px;
    left:460px;
    /* top:76.4%;
    left:61%; */
}

.definition-panel-wrapper .definition-image-wrapper .item5{
    position: absolute;
    top:520px;
    left:208px;
    /* top:90%;
    left:25%; */
}

.box-border{
    border: 3px solid rgb(0,131,211);
    background-color: #FFF;
    border-radius: 1rem;
    padding: 1rem 0.5rem;
    width: 200px;
    color: rgb(0,131,211);;
    text-align: center;
    cursor: pointer;
    font-weight: 600;

}

.box-border:hover{
    transform: scale(1.1);
    transition: .1s linear all; ;
}

.definition.definition-footer-link{
    display: block;
    font-size: 1.5rem;
    font-weight: normal!important;
    margin: 1rem auto;
    margin-left: 5%;
    color:#000;
    font-weight: lighter;
}

/*definition end */

.overlay-panel-container{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top:0px;
    left:0px;
    height: 100%;
    width: 100%;
    background-color: #00000055;
    z-index: 99999;

}


.overlay-panel,
.overlay-panel-home{
    position: relative;
    background-color: white;
    width: 80%;
    height: auto;
    z-index: 100000;
    padding: 2rem;
    overflow-y: hidden;
}

.overlay-panel{
    padding: 2rem 0;
    min-height: 500px;
    max-height: 90%;
    display: grid;
    grid-template-rows: 5rem auto 0rem;
    box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.5);
}

.overlay-panel-home{
    padding: 2rem 0;
    min-height: 200px;
    max-height: 700px;
}

.overlay-panel.view-mode-message{
    display: flex;
    min-height: 2rem;
    max-width: 35rem;
    justify-content: center;
}

.view-mode-message .panel-content{

}

.view-mode-message .panel-content .message-title{
    font-size: 1.25rem;
}

.view-mode-message .button-wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    left: auto;
    transform: none;
    margin: 1rem 0 0 0;
}

.view-mode-message .button-wrapper button{
    margin:0 0.5rem
}

.shadow {
    -moz-box-shadow:    3px 3px 5px 6px #000;
    -webkit-box-shadow: 3px 3px 5px 6px #000;
    box-shadow:         13px 13px 15px 16px #000;
  }


.overlay-panel .close,
.overlay-panel-home .close{
    position: absolute;
    height: 3rem;
    width: 3rem;
    cursor:pointer;
    text-align: center;
    vertical-align: middle;
    font-size: 2rem;
    line-height: 3rem;
}

.overlay-panel .close::after,
.overlay-panel-home .close::after{
    content: "X";
}


.overlay-panel .close:hover{
    background-color: #00000022;
}

.overlay-panel .definition-header{
    font-size: 2rem;
    font-weight: bold;
    margin-left: 2rem;
}

.overlay-panel .section-wrapper{
    padding: 0 2rem;
    max-height: 60vh;
    
    overflow: auto;
}

.overlay-panel .section
{
    padding: 1.5rem 0 1rem 0;
    font-size: 1.5rem;

}

.overlay-panel-home .section{
    padding: 0 1.5rem 1rem 1.5rem;
    font-size: 1.5rem;
    margin-top: 1.5rem;
    height: calc(100% - 1.5rem);
    overflow-y: auto;

}

.overlay-panel .section span.section-title{
    font-weight: bold;  
}

.overlay-panel-home .section>p,
.overlay-panel .section>p{
    margin:0;
}

.overlay-panel .close,
.overlay-panel-home .close{
    position: absolute;
    right: 0;
    top:0;
}

.gallery{
    grid-template-columns: 0 auto 0;
    display: grid;
    width:90%;
    max-width: 1024px;
    max-height: 90vh;
    height: 100%;
    margin:auto;
}

.gallery .navigator{
    height: 100%;
    width: 100%;
    display: flex;
    justify-items: center;
    justify-content: center;
    align-items: center;
}

.gallery .arrow{
    margin:1rem;
    height: 3rem;
    width: 3rem;
    cursor: pointer;
}

.navigator.back{
    grid-column: 1;
}

.navigator.next{
    grid-column: 3;
}


.arrow.back{
    transform: rotateY(180deg);
}

.arrow.next{

}

.overlay-panel-home{
    position: relative;
    background: #FFF;
    max-width: 1024px;
    width: 100%;
    margin-left:auto;
    margin-right: auto;
    grid-column: 2;
    overflow: hidden;
}


.overlay-panel-home .section p{
    margin-top: 1rem;
    font-size: 1.5rem;
    text-align: justify;
}

.overlay-panel-home .section p span.points{
    font-weight: bold;
    margin-left: 2rem;
}

.overlay-panel .actions{
    font-weight: 600;
    position: relative;
    display: block;
    width: 100%;
    grid-template-columns: 10rem auto 10rem;
}

.previous-card{
    position: relative;
    grid-column: 1;float: left;
    cursor: pointer;
}
.next-card{
    position: relative;
   grid-column: 3;
   float: right;
   cursor: pointer;
}

.intro-wrapper{
    position: relative;
    float: left;
    width: 100%;
    min-height: 30rem;
}

.intro-wrapper .intro{
    font-family: "Open Sans";
    margin-top: 1.5rem;
    position: relative;
    width: 100%;
    /* min-height: 25rem; */
}

.intro-section .intro-wrapper .intro h1,.intro h1{
    font-size:1.75rem;
    font-weight: 600;
}

.intro-section .intro-wrapper .intro h2,.intro h2{
    font-size:1.5rem;
    font-weight: 600;
}

.intro-section .intro-wrapper .intro p,.intro p{
    font-size: 1.25rem;
}

.intro.question-title p{
    font-size: 1.2rem;
}

.intro-section .intro-wrapper .intro li,.intro li{
    list-style: square inside none;
    font-size: 1.25rem;
}

.intro-section .intro-wrapper .intro img,.intro img{
    width: auto;
    height: auto;
    margin: 1.5rem 0 1.5rem 0;
}

.intro-section .intro-wrapper .code-playground{
    margin-top: 2rem;
}
/* Not required .intro-section .intro-wrapper .intro pre{
    background-color: #EEEEEE77;
    padding: 1rem;
}

.intro-section .intro-wrapper .intro code{
    font-size: 1rem;
    
} */

.intro .intro-code-block{
    margin-bottom: 1rem;
}

.intro code,
.intro.question-title code,
.intro.answer-text code{
    background: #EEE;
    padding:2px 4px;
    /* margin:0px 4px; */
    border-radius: 5px;
    font-size: 1rem;
    font-family: monospace!important;
    /* font-weight: 600; */
    color: black ;
    white-space: pre-line;
}

.intro code{
    white-space: normal;
}



.intro-wrapper>iframe{
    position: relative;
    width: 100%;
    min-height: 30rem;
    /* height: fit-content; */
}


/* styling registration color, background */
.reg-style-wrapper .form-group input{
    background-color: #028ccf;
    color: #fff;
}

.reg-style-wrapper .form-group input::placeholder {
    color: white;
    opacity: 1; /* Firefox */
  }

.reg-style-wrapper .form-group input[disabled] {
    opacity: 0.4;
  }

  /* status section */

  .container-center{
      margin: 5% 0;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
  }

  .status-tile{
      background-color: #e7e6e6;
      height: 25rem;
      width: 18rem;
      margin: 1rem 1.5rem;
      padding: 3rem 1.5rem;
      float: left;
  }

  @media only screen and (max-width: 1024px) {

    .container-limit.wide-limit{
        max-width: 768px;
    }
    
  }

  .status-tile .status-header{
    font-size: 2rem;
    line-height: 1;
    font-weight:bold;
    margin: 1rem 0 ;
    height: 2.5rem;
  }

  .status-tile .section-header{
    font-size: 1.25rem;
    font-weight: bold;
    margin-top: 2.5rem;
  }

  .status-tile .section-content{
    font-size: 1.25rem;

  }

  .status-text-wrapper{
      width: 100%;
      display: flex;
      justify-content: center;
  }

  .status-text{
      font-size: 2rem;
      font-weight: bold;
  }

  .status-text-wrapper .leaderboard-link{
    margin-left: 1rem;
    font-size: 2rem;
    font-weight: bold;
  }

  @media only screen and (max-width: 1024px) {
    .status-text-wrapper{
        margin: 1rem 5rem;
        display: flex;
        justify-content: center;
    }
  
    .status-text{
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
    }
  
    .status-text-wrapper .leaderboard-link{
      margin-left: 1rem;
      font-size: 2rem;
      font-weight: bold;
    }
}

  /* status section end */

  /* taskpool */

  .tasks{
      margin-top: 3rem;
  }

  /* taskpool end */

  /*  Loading Panel */

.loading-container{
    height: calc(100vh - (25vh + 85px));
    background-color: #EEEEEE05;
    display: grid;
    justify-content: center;
    align-items: center;
}

.loading-container .loading-panel{
    font-size: 3rem;
    font-weight: bold;
}
/* Loading Panel end */

p.intro-desc{
    margin-left: 1rem;
}

p.intro-desc strong{
    font-weight:bold
}
.message-wrapper{
    width:100%;
    display: flex;
    justify-content: center;
}


/* Course Content section */

/* - coding console */

.intro-wrapper .code-panel-wrapper{
    display: grid;
    width: 100%;
    margin: 1rem 0;
    position: relative;
    /* grid-template-columns: 50% 50%; */
}   

.intro-wrapper .code-panel-wrapper .code-panel{
    /* grid-column: 1; */
    /* display: grid; */
    
}

.intro-wrapper .code-panel-wrapper .code-panel .react-codemirror2{
    border: 1px solid #DDD;
}

.intro-wrapper .code-panel-wrapper .code-panel button{
    /* height: 1.5rem; */
    float: right;
    background: #EEE;
    margin: 2px;
    padding:5px 10px;
    border:none

}

.intro-wrapper .code-panel-wrapper .code-panel button i{
}

.intro-wrapper .code-panel-wrapper .terminal-panel{
    border:1px solid #DDD;
}

.intro-wrapper .code-panel-wrapper .terminal-panel .terminal-block{

}

.loader-panel{
    height: 100%;
    width: 100%;
    display: grid;
    position: absolute;
    top:0;
    left: 0;
    z-index: 10;
    background: #000000BB;
}

/* cm-s-idea updated the styling component */
.intro-wrapper .code-panel-wrapper .terminal-panel .terminal-block .cm-s-idea{
    font-family: monospace;
    font-size:16px;
    background: #272822;
    color:#f8f8f2;
}

.intro-wrapper .code-panel-wrapper .terminal-panel .terminal-block .CodeMirror pre.CodeMirror-line{
    white-space: normal;
}

.code-panel-wrapper .code-panel .alert{
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding-top: 0;
    padding-bottom: 0;
}



/* - coding console end */


/* Course Content section  end*/